.row_number.css-1eyncsv {
  text-align: right;
}

table .chakra-radio-group.css-0 {
  display: none;
}

.chakra-input.css-1kp110w {
  width: 25%;
}
